import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  Link,
  Text as Typography,
} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronUpIcon } from "@chakra-ui/icons";

import {
  useGetCareersMutation,
  useListCareersSearchMutation,
} from "store/jobs.slice";

import LoadingPage from "components/app/Loading";
import Footer from "components/app/Footer";
import NavBar from "components/app/Navbar";
import JobRecruiter from "./job-recruiter";

const JobDetailsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [reqList] = useListCareersSearchMutation();
  const [reqGetCareers, resGetCareers] = useGetCareersMutation();

  const { jobData, jobCareersPgBtn } = useSelector((state: any) => state.jobs);

  const jobID = params.jobId;

  const isOpenJobAd = jobData?.job_status === "Open";

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const utm_id = queryParameters.get("utm_id");

    if (utm_id) {
      window.sessionStorage.setItem("utm_id", utm_id);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(jobData).length === 0 && jobID) {
      reqList(jobCareersPgBtn);
      reqGetCareers({ id: Number(jobID) });
    }
  }, [jobCareersPgBtn, jobID]);

  const [htmlContent, setHtmlContent] = useState(jobData?.description);

  useEffect(() => {
    const parser = new window.DOMParser();
    const doc = parser.parseFromString(
      jobData?.description?.replace(/<div><\/div>/g, "<br />"),
      "text/html"
    );

    // Define a recursive function to remove style attributes
    function removeStyleAttributes(element: Node) {
      if (element.nodeType === Node.ELEMENT_NODE) {
        const elem = element as Element;
        // Remove the style attribute from the current element

        elem.setAttribute(
          "style",
          "font-family: NunitoSans Regular; font-size: 14px;"
        );

        // Recursively process child elements
        for (let i = 0; i < elem.childNodes.length; i++) {
          removeStyleAttributes(elem.childNodes[i]);
        }
      }
    }

    // Start the removal process from the document root
    removeStyleAttributes(doc.documentElement);

    // Get the modified HTML
    const modifiedHtml = doc.body.innerHTML;

    // Update the component state with the modified HTML
    setHtmlContent(modifiedHtml);
  }, [htmlContent, jobData?.description]);

  return (
    <Box bg="default.white.800" fontFamily="NunitoSans Regular" zIndex={1}>
      <NavBar />

      {resGetCareers.isLoading ? (
        <LoadingPage />
      ) : (
        <Box
          id="top"
          bgImage="linear-gradient(90deg, #6930CA 0%, #7925D6 46.5%, #B100FF 100%)"
          bgSize="cover"
          bgPosition="center"
          height="50vh"
          position="relative"
        >
          <Container maxW={["100%", "80%"]}>
            <Box margin="0 auto" pt={["50px", "100px"]}>
              <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="white" />}
                p={["16px 30px", "16px 0"]}
                fontSize="0.875rem"
              >
                <BreadcrumbItem>
                  <BreadcrumbLink href="/" color="#FFFFFF80">
                    Job Listing
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink isCurrentPage color="white">
                    Job Details
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <JobRecruiter />

              <Flex
                direction={{ base: "column-reverse", md: "row" }}
                justify="space-between"
                mt="50px"
                mb="100px"
              >
                {isOpenJobAd && (
                  <>
                    <Box
                      width={{ base: "100%", md: "70%" }}
                      p={["24px", "24px 48px 24px 0"]}
                      borderRight={{ base: "none", md: "1px solid #f2f4f9" }}
                    >
                      <Box mb="32px">
                        <Typography
                          fontSize="24px"
                          fontWeight={700}
                          mb="16px"
                          color="#B100FF"
                          fontFamily="NunitoSans Bold"
                        >
                          Job Description
                        </Typography>
                        <Box mb="32px" sx={{ li: { ml: "30px" } }}>
                          {jobData?.description && parse(htmlContent)}
                        </Box>
                      </Box>
                      <Button
                        variant="solid"
                        background="linear-gradient(90deg,#4900d9 0,#b100ff 100%)"
                        border="none"
                        mt="50px"
                        fontSize="16px"
                        onClick={() => {
                          navigate(`/job-application/${jobData?.id}`);
                        }}
                        sx={{
                          fontFamily: "NunitoSans Bold",
                          position: ["initial"],
                          bottom: ["17px"],
                          left: ["0"],
                          right: ["0"],
                          width: ["90%", "initial"],
                          borderRadius: "50px",
                          margin: ["0 auto", "initial"],
                          padding: ["28px 16px", "11px 24px"],
                        }}
                      >
                        I'm interested
                      </Button>
                    </Box>

                    <Box
                      width={{ base: "100%", md: "30%" }}
                      p={["24px", "24px 48px"]}
                      color="#2B2D42"
                    >
                      <Typography
                        fontSize="24px"
                        fontWeight={700}
                        mb="16px"
                        color="#B100FF"
                        fontFamily="NunitoSans Bold"
                      >
                        Job Information
                      </Typography>
                      {jobData?.experience_level && (
                        <Box mb="16px">
                          <Typography
                            fontWeight={600}
                            mb="4px"
                            fontSize="0.875rem"
                          >
                            Work Experience
                          </Typography>
                          <Typography fontSize="0.875rem">
                            {jobData?.experience_level}
                          </Typography>
                        </Box>
                      )}
                      {jobData?.specification && (
                        <Box mb="16px">
                          <Typography
                            fontWeight={600}
                            mb="4px"
                            fontSize="0.875rem"
                          >
                            Industry
                          </Typography>
                          <Typography fontSize="0.875rem">
                            {jobData?.specification}
                          </Typography>
                        </Box>
                      )}
                      {jobData?.salary_start > 0 && jobData?.salart_end > 0 && (
                        <Box mb="16px">
                          <Typography
                            fontWeight={600}
                            mb="4px"
                            fontSize="0.875rem"
                          >
                            Salary
                          </Typography>
                          <Typography fontSize="0.875rem">
                            ${jobData?.salary_start?.toLocaleString("en-US")} -
                            ${jobData?.salart_end?.toLocaleString("en-US")}
                          </Typography>
                        </Box>
                      )}
                      {jobData?.workplace_type && (
                        <Box mb="16px">
                          <Typography
                            fontWeight={600}
                            mb="4px"
                            fontSize="0.875rem"
                          >
                            Work Setup
                          </Typography>
                          <Typography fontSize="0.875rem">
                            {jobData?.workplace_type}
                          </Typography>
                        </Box>
                      )}
                      {jobData?.office?.city &&
                        jobData?.workplace_type != "Remote" && (
                          <Box mb="16px">
                            <Typography
                              fontWeight={600}
                              mb="4px"
                              fontSize="0.875rem"
                            >
                              City
                            </Typography>
                            <Typography fontSize="0.875rem">
                              {jobData?.office?.city}
                            </Typography>
                          </Box>
                        )}
                      {jobData?.office?.state &&
                        jobData?.workplace_type != "Remote" && (
                          <Box mb="16px">
                            <Typography
                              fontWeight={600}
                              mb="4px"
                              fontSize="0.875rem"
                            >
                              State/Province
                            </Typography>
                            <Typography fontSize="0.875rem">
                              {jobData?.office?.state}
                            </Typography>
                          </Box>
                        )}
                      {jobData?.office?.country &&
                        jobData?.workplace_type != "Remote" && (
                          <Box mb="16px">
                            <Typography
                              fontWeight={600}
                              mb="4px"
                              fontSize="0.875rem"
                            >
                              Country
                            </Typography>
                            <Typography fontSize="0.875rem">
                              {jobData?.office?.country}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  </>
                )}
              </Flex>
            </Box>
          </Container>

          <Footer />
        </Box>
      )}

      <Box>
        <Link
          href="#top"
          sx={{
            background: "linear-gradient(90deg,#4900d9 0,#b100ff 100%)",
            display: "block",
            position: "fixed",
            bottom: "80px",
            right: "16px",
            fontSize: "25px",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            textAlign: "center",
            color: "white",
            _hover: {
              color: "white",
            },
          }}
        >
          <ChevronUpIcon />
        </Link>
      </Box>
    </Box>
  );
};

export default JobDetailsPage;
